// import autenticacaoService from '@/services/autenticacao'
import agendamentoService from '@/services/agendamento'
import { extractOnlyNumbers } from '@/utils/formatter'
import empresa from './empresa'
import funcionarios from './funcionarios'

export const SET_STEP = 'SET_STEP'
export const SET_CIDADE = 'SET_CIDADE'
export const SET_CLINICA = 'SET_CLINICA'
export const SET_CNPJ = 'SET_CNPJ'
export const SET_CLINICA_HORARIOS = 'SET_CLINICA_HORARIOS'
export const SET_CLINICA_HORARIO_AGENDADO = 'SET_CLINICA_HORARIO_AGENDADO'
export const RESET_STATE = 'RESET_STATE'
export const SET_TIPO_AGENDAMENTO = 'SET_TIPO_AGENDAMENTO'
export const SET_MESSAGE_TS = 'SET_SLACK'
export const SET_TIPO_PAGAMENTO = 'SET_TIPO_PAGAMENTO'
export const SET_RECESSOS = 'SET_RECESSOS'

const getDefaultState = () => {
  return {
    actualStep: 1,
    cidade: null,
    clinica: null,
    clinica_horarios: null,
    clinica_horario_agendado: false,
    tipoAgendamento: 'presencial',
    tipoPagamento: null,
    message_ts: null,
    recessos: [],
  }
}

const state = getDefaultState()

const mutations = {
  [SET_STEP]: (state, step) => (state.actualStep = step),
  [SET_CIDADE]: (state, cidade) => (state.cidade = cidade),
  [SET_CLINICA]: (state, clinica) => (state.clinica = clinica),
  [SET_CLINICA_HORARIOS]: (state, horarios) => (state.clinica_horarios = horarios),
  [SET_CLINICA_HORARIO_AGENDADO]: (state, agendado) => (state.clinica_horario_agendado = agendado),
  [RESET_STATE]: state => Object.assign(state, getDefaultState()),
  [SET_TIPO_AGENDAMENTO]: (state, value) => (state.tipoAgendamento = value),
  [SET_MESSAGE_TS]: (state, value) => (state.message_ts = value),
  [SET_TIPO_PAGAMENTO]: (state, tipoPagamento) => (state.tipoPagamento = tipoPagamento),
  [SET_RECESSOS]: (state, recessos) => (state.recessos = recessos),
}

const getters = {
  cidade: state => state.cidade,
  clinica: state => state.clinica,
  clinica_horarios: state => state.clinica_horarios,
  clinica_horario_agendado: state => state.clinica_horario_agendado,
  tipoAgendamento: state => state.tipoAgendamento,
  getActualStep: state => state.actualStep,
  getSlackMessage: state => state.message_ts,
  getTipoPagamento: state => state.tipoPagamento,
  recessos: state => state.recessos?.map(recesso => new Date(recesso.data)),
}

const actions = {
  resetAgendamento: ({ commit }) => {
    commit('empresa/resetState')
    commit('funcionarios/resetState')
    commit(RESET_STATE)
  },
  sendLeadsDataToSlack: async ({ state, commit }, usuario) => {
    const payload = {
      message_ts: state.message_ts,
      usuario: {
        ...usuario,
      },
      empresa: {
        cnpj: state.empresa.empresa.cnpj,
        razao_social: state.empresa.empresa.razao_social,
        segmento: state.empresa.segmento
          ? state.empresa.segmentos.find(segmento => segmento.id === state.empresa.segmento).nome
          : null,
        qtde_funcionarios: state.empresa.empresa.qtde_funcionarios,
      },
    }
    const { data } = await agendamentoService.sendLeadsDataToSlack(payload)
    commit(SET_MESSAGE_TS, data.message_data.ts)
  },
  finishAgendamentoExpress: async ({ getters, dispatch }, creditCardInfo = {}) => {
    const getEmpresa = getters['empresa/getEmpresa']

    const empresa = getEmpresa.empresaId
      ? { id_empresa: getEmpresa.empresaId }
      : { ...getEmpresa, cnpj: extractOnlyNumbers(getEmpresa.cnpj) }

    const params = {
      tipoPagamento: getters.getTipoPagamento,
      idClinica: getters.clinica,
      tipoAgendamento: getters.tipoAgendamento,
      funcionarios: global.structuredClone(getters['funcionarios/getFuncionarios']),
      empresa,
      ...creditCardInfo,
    }

    const response = await agendamentoService.finalizarAgendamento(params)

    await dispatch('resetAgendamento')
    return response
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {
    empresa,
    funcionarios,
  },
}
