<template>
  <v-form ref="form">
    <v-row class="mt-1">
      <v-col cols="6">
        <v-combobox
          class="mt-1"
          v-model="funcionario.funcao"
          :items="funcoes"
          outlined
          :rules="[v => !!v || 'Campo obrigatório']"
          @update:search-input="getFuncoes"
          label="Busque ou crie uma função"
          :loading="loadingFuncao"
          append-icon="mdi-chevron-down-circle"
          @on-change="clearExamesERiscos"
          @loading="loadingFuncao = $event"
          @input="getCbos(funcionario.funcao, 'funcao')"
          placeholder="Busque ou crie uma função"
        />
      </v-col>
      <v-col cols="6">
        <CustomAutocomplete
          class="mt-1"
          v-model="funcionario.cbo"
          :items="cbos"
          @on-search="value => getCbos(value, 'cbo')"
          label="Busque por um CBO associado a função"
          :loading="loadingCbo"
          @on-change="clearExamesERiscos"
          @loading="loadingCbo = $event"
          noDataText="Ops! Não encontramos nenhum CBO"
          placeholder="Busque por um CBO"
        />
      </v-col>
      <v-col cols="12">
        <CustomAutocomplete
          class="mt-1"
          v-model="funcionario.setor"
          :items="setores"
          @on-search="getSetores"
          label="Busque ou crie um setor"
          :loading="loadingSetor"
          @on-change="clearExamesERiscos"
          @loading="loadingSetor = $event"
          noDataText="Ops! Não encontramos nenhum setor com esse nome"
          placeholder="Busque ou crie um setor"
        />
      </v-col>
      <v-col cols="12" v-if="tipoAgendamento === 'presencial'">
        <v-text-field
          v-model="funcionario.dt_agendamento"
          :rules="[
            () => $v.funcionario.dt_agendamento.required || 'Campo obrigatório',
            () => $v.funcionario.dt_agendamento.validDate || 'Data inválida',
            () =>
              $v.funcionario.dt_agendamento.validDateAgendado ||
              'Data precisa ser marcada com, no mínimo, dois dias úteis de antecedência',
            () => $v.funcionario.dt_agendamento.validBusinessDateAgendado || 'A data precisa ser um dia útil',
            () => $v.funcionario.dt_agendamento.recesso || 'Nesta data, a clínica estará em recesso',
          ]"
          outlined
          label="Data exame"
          v-mask="'##/##/####'"
        ></v-text-field>

        <v-alert
          type="info"
          transition="slide-y-reverse-transition"
          :value="!!funcionario.dt_agendamento && tipoAgendamento === 'presencial'"
        >
          Atenção! Essa clínica atende nos horários: {{ clinica_horarios }}.
          <b>Portanto o seu horário pode ser alterado caso esteja incorreto</b>
        </v-alert>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapMutations, mapGetters } from 'vuex'
// eslint-disable-next-line import/no-extraneous-dependencies
import agendamentoService from '@/services/agendamento'
import { addBusinessDays, parse, isWeekend, isValid, isEqual } from 'date-fns'
import { dateRegex } from '@/utils/constants'
import agendamentoMixin from '@/mixins/agendamentoMixin'
import CustomAutocomplete from '@/components/common/CustomAutoComplete.vue'

export default {
  mixins: [validationMixin, agendamentoMixin],
  validations: {
    funcionario: {
      dt_agendamento: {
        required,
        validDate(dt_agendamento) {
          if (dt_agendamento) {
            const dt_agendamento_formatted = parse(dt_agendamento, 'dd/MM/yyyy', new Date())
            return dateRegex.test(dt_agendamento) && dt_agendamento_formatted > new Date(Date.now())
          }
          return false
        },
        validDateAgendado(dt_agendamento) {
          if (this.horario_agendado && dt_agendamento) {
            const dt_agendamento_formatted = parse(dt_agendamento, 'dd/MM/yyyy', new Date())
            return dateRegex.test(dt_agendamento) && dt_agendamento_formatted > addBusinessDays(new Date(Date.now()), 2)
          }
          return true
        },
        validBusinessDateAgendado(dt_agendamento) {
          if (dt_agendamento) {
            const dt_agendamento_formatted = parse(dt_agendamento, 'dd/MM/yyyy', new Date())
            return dateRegex.test(dt_agendamento) && !isWeekend(dt_agendamento_formatted)
          }
          return true
        },
        recesso(dt_agendamento) {
          const valueDate = parse(dt_agendamento, 'dd/MM/yyyy', new Date())
          if (!isValid(valueDate)) return true
          return !this.recessos.some(recesso => isEqual(valueDate, recesso))
        },
      },
    },
  },
  components: {
    CustomAutocomplete,
  },
  props: {
    funcionario: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      funcoes: [],
      setores: [],
      cbos: [],
      loadingFuncao: false,
      loadingSetor: false,
      loadingCbo: false,
      horario_agendado: false,
    }
  },
  computed: {
    ...mapGetters('agendamento', ['clinica_horarios', 'tipoAgendamento', 'clinica_horario_agendado', 'recessos']),
    ...mapGetters('agendamento/funcionarios', ['getCurrentFuncionarioIndex']),
  },
  methods: {
    ...mapMutations('agendamento/funcionarios', ['setExames', 'setRiscos']),
    ...mapMutations('funcao', { setFuncoes: 'SET_FUNCOES', resetFuncoes: 'RESET_FUNCOES' }),
    ...mapMutations('setor', { setSetores: 'SET_SETORES' }),
    ...mapMutations('cbo', { setCbos: 'SET_CBOS' }),
    async getFuncoes(funcao) {
      try {
        this.loadingFuncao = true
        const { data } = await agendamentoService.buscaFuncoes(funcao)
        this.funcoes = data
      } catch ({ response }) {
        this.showErrorOnSnackbar(response.data.message)
      } finally {
        this.loadingFuncao = false
      }
    },
    async getSetores(setor) {
      try {
        this.loadingSetor = true
        const { data } = await agendamentoService.buscaSetores(setor)
        this.setores = data
      } catch ({ response }) {
        this.showErrorOnSnackbar(response.data.message)
      } finally {
        this.loadingSetor = false
      }
    },
    async getCbos(cbo, campo) {
      try {
        this.loadingCbo = true
        const { data } = await agendamentoService.buscaCbos(cbo)
        this.cbos = data

        if (campo === 'funcao') {
          // eslint-disable-next-line no-extra-semi
          ;[this.funcionario.cbo] = this.cbos
        }
      } catch ({ response }) {
        this.showErrorOnSnackbar(response.data.message)
      } finally {
        this.loadingCbo = false
      }
    },
    validate() {
      return this.$refs.form.validate()
    },
    resolveLoading(value) {
      this.loading = value
    },
    clearExamesERiscos() {
      this.funcionario.exames = null
      this.funcionario.riscos = null
    },
  },
  beforeMount() {
    this.actualStepWrapper = 5
    this.horario_agendado = this.clinica_horario_agendado
  },
  mounted() {
    if (this.funcionario.setor) {
      this.setores.push(this.funcionario.setor)
    }
    if (this.funcionario.cbo) {
      this.cbos.push(this.funcionario.cbo)
    }
  },
}
</script>

<style scoped></style>
